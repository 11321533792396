<template>
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-auto ms-auto">
        <span
          class="badge rounded-pill bg-secondary cursor-pointer me-2"
          v-if="genderFilter"
          @click="genderFilter = ''"
          >Clear</span
        >
        <span
          class="badge rounded-pill bg-success cursor-pointer me-2"
          @click="genderFilter = 'female'"
          v-if="genderFilter == 'female' || !genderFilter"
          >Female</span
        >
        <span
          class="badge rounded-pill bg-success cursor-pointer me-2"
          @click="genderFilter = 'male'"
          v-if="genderFilter == 'male' || !genderFilter"
          >Male</span
        >
      </div>
      <!-- <div class="col-auto">
        <span
          class="badge rounded-pill bg-secondary cursor-pointer me-2"
          v-if="typeFilter"
          @click="typeFilter = ''"
          >Clear</span
        >

      </div> -->
    </div>
    <div class="card border-0 shadow-none" v-if="clients">
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Gender</th>
              <th scope="col">Joined</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="c in clients.data" :key="c.id">
              <td @click="$router.push('/clients/' + c.id)">{{ c.name }}</td>
              <td @click="$router.push('/clients/' + c.id)">{{ c.email }}</td>
              <td
                @click="$router.push('/clients/' + c.id)"
                class="text-capitalize"
              >
                {{ c.gender || "not set" }}
              </td>
              <td @click="$router.push('/clients/' + c.id)">
                {{ c.created_at | formatDate }}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row mt-4">
          <div class="col-md-4 my-auto">
            <button
              class="btn btn-sm btn-outline-success"
              @click="clients.current_page = 1"
              :disabled="clients.current_page == clients.first_page"
            >
              First Page
            </button>

            <button
              class="btn btn-sm btn-outline-success ms-3"
              @click="clients.current_page--"
              :disabled="!clients.prev_page_url"
            >
              Previous
            </button>
          </div>
          <div class="col-md-4 my-auto text-center">
            <p class="mb-0">
              Page {{ clients.current_page }} of
              {{ clients.last_page }}
            </p>
          </div>
          <div class="col-md-4 my-auto text-end">
            <button
              class="btn btn-sm btn-outline-success cursor-pointer me-3"
              @click="clients.current_page++"
              :disabled="clients.current_page == clients.last_page"
            >
              Next
            </button>

            <button
              class="btn btn-sm btn-outline-success cursor-pointer"
              @click="clients.current_page = clients.last_page"
              :disabled="!clients.last_page_url"
            >
              Last Page
            </button>
          </div>
        </div>
      </div>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      clients: {
        current_page: 1,
      },
      genderFilter: this.$route.query.gender ? this.$route.query.gender : "",
    };
  },
  watch: {
    "clients.current_page": {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: this.clients.current_page,
                gender: this.genderFilter,
              },
            })
            .catch(() => {});
          this.fetchClients();
        }
      },
    },
    genderFilter: {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                gender: this.genderFilter,
              },
            })
            .catch(() => {});
          this.fetchClients();
        }
      },
    },
    typeFilter: {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: 1,
                gender: this.genderFilter,
              },
            })
            .catch(() => {});
          this.fetchClients();
        }
      },
    },
  },
  methods: {
    fetchClients(paginationUrl) {
      console.log(paginationUrl);
      this.$axios(process.env.VUE_APP_API_URL + this.$route.fullPath).then(
        ({ data }) => {
          this.clients = data;
        }
      );
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
    this.fetchClients();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LLL");
    },
    formatType(type) {
      return type.replace(/_/g, " ");
    },
    formatStatus(status) {
      return status.replace(/_/g, " ");
    },
  },
  components: {
    Loading,
  },
};
</script>

<style></style>
